@import '../../Anim';

.sms-validator {
  &__confirm-modal {
    &__phone {
      text-align: center;
      margin: var(--app-margin-default);
      font-size: var(--app-font-size-highlight);
    }
  }

  &__code-modal {
    & .alert-input {
      text-align: center;
      font-size: 40pt;
    }

    &__validate {
      font-weight: bold;
    }

    &__resend {
      font-weight: normal !important;
    }
  }
}
.internation-phone-input {

  .form-control {
    background-color: transparent;
    border: none;
    width: calc(100% - var(--app-margin-default));
    padding: 10px 21px 10px 48px;
  }

  .flag-dropdown {
    border: none;
    background-color: transparent;
  }

  .country-list {
    z-index: 999999999;
  }
}
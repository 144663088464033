@import '../../App.scss';

.field {
  &__title {
    @extend .app-text-detail;
  }

  &__body {
    width: 100%;
    border-bottom: solid 1px var(--app-color-light-text-on-background);

    &__base {
      @extend .app-text-body--small;
      padding-left: var(--app-margin-small);
      padding-right: var(--app-margin-small);
      min-height: 35px;
      border: 0px;
      background: var(--app-color-background);
    }

    &__input, &__select{
      @extend .field__body__base;
      width: calc(100% - var(--app-margin-default));
    }

    &__select {
      z-index: 1;
    }

    &__textarea {
      @extend .field__body__base;
      width: calc(100% - var(--app-margin-default));
    }

    &__file {
      display: flex;
      align-items: center;

      &__file-input {
        display: none;
      }

      &__input {
        @extend .app-text-body--small;
        display: flex;
        align-items: center;
        flex-grow: 1;
        height: var(--app-height-button);
        cursor: pointer;
      }

      &__clean {
        @extend .app-button--no-shadow;
        box-shadow: none;
        shape-outside: none;
      }
    }
  }

  &__error {
    @extend .app-text-body--small;
  }
}

/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-color-primary: #75bccd;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #67a5b4;
  --ion-color-primary-tint: #83c3d2;

  --ion-color-secondary: #e9a32c;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #cd8f27;
  --ion-color-secondary-tint: #ebac41;

  --ion-color-tertiary: #a9b6b8;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #95a0a2;
  --ion-color-tertiary-tint: #b2bdbf;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #e4e7e9;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #3f3f3f;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c9cbcd;
  --ion-color-light-tint: #e7e9eb;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-primaryContrast {
  --ion-color-base: var(--ion-color-primary-contrast);
  --ion-color-contrast: #000000;
  --ion-color-shade: #e0e0e0;
  --ion-color-tint: #ffffff;
}

.ion-color-backgroundLight {
  --ion-color-base: var(--app-color-background-light);
  --ion-color-contrast: var(--app-color-text-on-background);
  --ion-color-shade: #dbdbdb;
  --ion-color-tint: #fafafa;
}

.ion-color-background {
  --ion-color-base: var(--app-color-background);
  --ion-color-contrast: var(--app-color-text-on-background);
  --ion-color-shade: #d6d6d6;
  --ion-color-tint: #f4f4f4;
}

.ion-color-backgroundDark {
  --ion-color-base: var(--app-color-background-dark);
  --ion-color-contrast: var(--app-color-text-on-background);
  --ion-color-shade: #cfcfcf;
  --ion-color-tint: #ededed;
}

.ion-color-textOnBackground {
  --ion-color-base: var(--app-color-text-on-background);
  --ion-color-contrast: #ffffff;
  --ion-color-shade: #525252;
  --ion-color-tint: #6d6d6d;
}

.ion-color-lightTextOnBackground {
  --ion-color-base: var(--app-color-light-text-on-background);
  --ion-color-contrast: #000000;
  --ion-color-shade: #b3b3b3;
  --ion-color-tint: #d0d0d0;
}

.ion-color-lesslightTextOnBackground {
  --ion-color-base: var(--app-color-less-light-text-on-background);
  --ion-color-contrast: #000000;
  --ion-color-shade: #86888f;
  --ion-color-tint: #a2a4ab;
}

.ion-color-darkTextOnBackground {
  --ion-color-base: var(--app-color-dark-text-on-background);
  --ion-color-contrast: #fff;
  --ion-color-shade: #000;
  --ion-color-tint: #000;
}

.ion-color-transparent {
  --ion-color-base: transparent;
  --ion-color-contrast: #ffffff;
  --ion-color-shade: transparent;
  --ion-color-tint: transparent;
}

.ion-color-transparent-contrast {
  --ion-color-base: transparent;
  --ion-color-contrast: var(--ion-color-primary);
  --ion-color-shade: transparent;
  --ion-color-tint: transparent;
}

.ion-color-transparentNormal {
  --ion-color-base: transparent;
  --ion-color-contrast: var(--app-color-text-on-background);
  --ion-color-shade: transparent;
  --ion-color-tint: transparent;
}

.ion-color-subscriber {
  --ion-color-base: var(--app-color-subscriber);
  --ion-color-contrast: #000;
  --ion-color-shade: #c0911c;
  --ion-color-tint: #deae36;
}

.ion-color-online {
  --ion-color-base: var(--app-color-online);
  --ion-color-contrast: #fff;
  --ion-color-shade: #71b428;
  --ion-color-tint: #8dd243;
}

.ion-color-veryLight {
  --ion-color-base: var(--app-color-very-light);
  --ion-color-contrast: var(--app-color-dark-text-on-background);
  --ion-color-shade: #e0e0e0;
  --ion-color-tint: #ffffff;
}

.ion-color-veryDark {
  --ion-color-base: var(--app-color-dark-text-on-background);
  --ion-color-contrast: var(--app-color-very-light);
  --ion-color-shade: #1e2023;
  --ion-color-tint: #383a3e;
}

.ion-color-highlight {
  --ion-color-base: var(--app-color-highlight);
  --ion-color-contrast: var(--ion-color-primary);
  --ion-color-shade: #c6d1d2;
  --ion-color-tint: #e4eff1;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}

/* App's variables */
:root {
  --app-color-primary-dark: #5fb6ca;
  --app-color-background-dark: #ebebeb;
  --app-color-background-dark-er: #e1e1e1;
  --app-color-background: #f3f3f3;
  --app-color-background-light: #fff;
  --app-color-dark-text-on-background: #222428;
  --app-color-text-on-background: #5d5d5d;
  --app-color-less-light-text-on-background: #989aa2;
  --app-color-light-text-on-background: #cbcbcb;
  --app-color-subscriber: #daa520;
  --app-color-online: #80cd2e;
  --app-color-ring-disabled: #d8d8d8;
  --app-color-ring-enabled: #EA6868;
  --app-color-very-light: #fff;
  --app-color-highlight: #e1edef;

  --app-font-size-title: 26px;
  --app-line-height-field: 1.29;
  --app-font-size-highlight: 24px;
  --app-font-size-small: 14px;
  --app-font-size-body: 16px;
  --app-font-size-large-body: 18px;
  --app-font-size-fineprint: 11px;
  --app-font-size-very-small: 12px;

  --app-letter-spacing-font-small: 1.25px;

  --app-margin-small: 5px;
  --app-margin-default: 10px;
  --app-margin-breath: 15px;
  --app-margin-large: 20px;
  --app-margin-extra-large: 30px;
  --app-padding-form: 38px;
  --app-padding-important: 50px;
  --app-padding-navbar: 44px;
  
  --app-margin-ion-chip: -5px;
  --app-margin-ion-item: -15px;
  --app-margin-ion-button: -10px;
  --app-margin-ion-fab: -10px;
  --app-margin-ion-input: -8px;
  --app-margin-bottom-iphonex: env(safe-area-inset-bottom);
  --app-margin-ios-statusbar: env(safe-area-inset-top);

  --app-height-button: 50px;
  --app-size-avatar: 50px;

  --app-border-radius-default: 4px;
}

@import 'theme';
@import 'utils';

@function assetUrl($type, $path) {
  @return url('../public/assets/' + $type + '/' + $path)
};

@function imageUrl($path) {
  @return assetUrl('img', $path)
};

@function fontUrl($name) {
  @return assetUrl('font', $name)
};

@font-face {
  font-family: 'app-primary';
  src: fontUrl('nunito_regular.ttf');
}

@font-face {
  font-family: 'app-primary-bold';
  src: fontUrl('nunito_bold.ttf');
}

@font-face {
  font-family: 'app-primary-semibold';
  src: fontUrl('nunito_bold.ttf');
}

@font-face {
  font-family: 'app-secondary';
  src: fontUrl('assistant_regular.ttf');
}

@font-face {
  font-family: 'app-secondary-semibold';
  src: fontUrl('assistant_semibold.ttf');
}

@font-face {
  font-family: 'app-secondary-bold';
  src: fontUrl('assistant_bold.ttf');
}

.app {
  &-text {
    &--bold {
      font-family: 'app-secondary-semibold';
    }

    &--danger {
      @extend .app-text--bold;
      color: var(--ion-color-danger);
      text-transform: uppercase;
    }

    &--primary {
      @extend .app-text--bold;
      color: var(--ion-color-primary);
      text-transform: uppercase;
    }

    &-title {
      font-family: 'app-primary-bold';

      &--high {
        @extend .app-text-title;
        font-size: var(--app-font-size-title)
      }

      &--medium {
        @extend .app-text-title;
        font-size: var(--app-font-size-highlight)
      }

      &--low {
        @extend .app-text-title;
        font-size: var(--app-font-size-large-body)
      }

      &--body {
        @extend .app-text-title;
        font-size: var(--app-font-size-body);
      }
    }

    &-body {
      font-family: 'app-secondary';
      font-size: var(--app-font-size-body);

      &--small {
        @extend .app-text-body;
        font-size: var(--app-font-size-small);
      }

      &--fineprint {
        @extend .app-text-body;
        font-size: var(--app-font-size-fineprint);
      }
    }

    &-detail {
      font-family: 'app-secondary-semibold';
      font-size: var(--app-font-size-body);

      &--large {
        @extend .app-text-detail;
        font-size: var(--app-font-size-large-body);
      }

      &--small {
        @extend .app-text-detail;
        font-size: var(--app-font-size-small);
      }
    }
  }

  &-toolbar {
    font-family: 'app-primary-bold';
  }
  
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--app-height-button);
    font-family: 'app-secondary';
    letter-spacing: .25px;
    text-transform: uppercase;

    &--no-shadow {
      min-width: var(--app-height-button);
      min-height: var(--app-height-button);
      --box-shadow: ;
    }
  
    &--disabled, &--outline {
      @extend .app-button;
    }

    &--outline {
      --box-shadow: ;
      text-transform: none;
      border: solid 0.75px white;
      border-radius: var(--border-radius);
    }

    &--icon {
      font-size: 30px;
    }
  }

  &-avatar {
    &-container {
      // background-image: imageUrl('avatar/placeholder_item.svg');
      background-color: #dee5e6;
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center center;
    }
    &-photo {
      width: var(--app-size-avatar);
      height: var(--app-size-avatar);
      max-width: var(--app-size-avatar);
      max-height: var(--app-size-avatar);
      min-width: var(--app-size-avatar);
      min-height: var(--app-size-avatar);
    }
  }

  &-bottom-stuff {
    margin-bottom: var(--app-margin-bottom-iphonex);
  }

  &-toolbar-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: calc(var(--app-margin-ion-fab) * -1);;
    margin-bottom: var(--app-margin-ion-fab);
    margin-right: var(--app-margin-ion-fab);
    width: calc(100% + var(--app-margin-ion-fab) * -1);
  }

  &-alert {
    & .sc-ion-alert-md, & .sc-ion-alert-ios {
      max-height: initial;
      text-align: center;
    }
  }
}

.sc-ion-alert-md, .sc-ion-alert-ios {
  font-family: 'app-secondary';
}

.textarea-wrapper, .native-textarea {
  min-height: 62px !important;
}

.single-select-alert {
  & .alert-wrapper {
    max-width: unset;
  }
  
  & .alert-radio-button {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
    
  & .alert-tappable.sc-ion-alert{
    &-ios, &-md {
      height: unset;
    }
  }

  & .alert-radio-label.sc-ion-alert {
    &-ios, &-md {
      white-space: unset !important;
      font-size: 14px;
    }
  }
 }
.full-height {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header-borderless {
  &.header-ios ion-toolbar {
    --border-width: 0;
  }
  &.header-md:after {
    background-image: none;
  }
}

.remove-padding-start {
  --padding-start: 0;
}

.remove-overflow {
  --overflow: hidden;
}


@import '../../App';

.continue-container {
  padding-bottom: calc(var(--app-height-button) + var(--app-margin-default));
}

.continue {
  width: 100%;
  margin-left: var(--app-margin-ion-fab);
  margin-right: var(--app-margin-ion-fab);
  margin-bottom: calc(var(--app-margin-ion-fab) + var(--app-margin-bottom-iphonex));

  &__button {
    @extend .app-button;
    width: 100%;
    background: var(--ion-color-secondary);
    text-transform: uppercase;
    text-align: center;
  }
}
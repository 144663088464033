@import './App';

$time-animation-default: 0.3s;

.app-animated{
  &-fade {
    &--in {
      animation: app-fadeIn $time-animation-default;
      opacity: 1;
    }
    
    @keyframes app-fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    &--out {
      animation: app-fadeOut $time-animation-default;
      opacity: 0;
    }
    
    @keyframes app-fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }
  }
}
@import '../../App';
@import '../../components/bottomButton/ContinueBottomButton';

.dpo {
  &__header {
    @extend .app-text-body;
    margin: var(--app-margin-large);
  }

  &__form {
    @extend .app-text-body;
    padding-top: var(--app-margin-default);
    padding-bottom: calc(var(--app-height-button) + var(--app-margin-default));
    padding-left: var(--app-margin-large);
    padding-right: 10;

    &__field {
      margin-bottom: calc(var(--app-margin-large) + var(--app-margin-small));
    }
  }
}
